export const API_KEY = 'bac87873a53869e58c0622ac74e9015046084d4f';
export const API_BASE_URL = 'https://uat-api.squarerisk.net/v2/';
export const SOCKET_BASE_URL = 'wss://uat-api.squarerisk.net';
export const SOCKET_SITE_PATH = '/v2/socket';
export const S3_BUCKET_NAME = 'square-risk-uat-us-east-files';
export const S3_BUCKET_REGION = 'us-east-1';
export const S3_BUCKET_COMPANY_LOGO_PATH = 'company/logo';
export const S3_BUCKET_COMPANY_FILE_PATH = 'company-product/policies';
export const S3_BUCKET_COMPAPY_DOCUMENTS_PATH = 'company/documents';
export const S3_BUCKET_COMPANY_DOCUMENTS_PATH = 'company/documents';
export const S3_BUCKET_FILE = 'pre-signature-templates';
export const STRIPE_API_KEY = 'pk_test_51MUP6qKwlwY6ryZJYEcGfTQEPb1akj8LRlAWzJkfOQRlQPMfOZLYpZEcBaUPs9U1Q2hX8xKisgyMKD5HApo5RJJh00NqUMoyNh';
export const AGENT_PANEL_BASE_URL = 'https://uat.squarerisk.net';
export const ZOHO_CHAT_KEY = 'siq5154518ba68248cc63ab567ee4fcd83fa3b035fc9bf68dbf7643a796b8ea5bab';
export const GOOGLE_API_KEY = 'AIzaSyCdRRH43THUGAVQ83hyI15F1C1vjB0WrRs';
